import http from "@/config/axios/axios";
import qs from 'qs'
import Vue from 'vue'
import router from '@/router'

export default {
    state: {
        total: 0,
        news: [],
        new: [],
    },
    getters: {
        getNews: state => state.news,
        getNew: state => state.new,
    },
    actions: {
        async getNews({commit}, filters) {
            try {
                const res = await http.get('/news?' + qs.stringify(filters))
                commit('SET_NEWS', res.data.data)
            } finally {
                console.log('finally')
            }
        },
        async getNew({commit}, id) {
            try {
                const res = await http.get('/news/' + id)
                commit('SET_NEW', res.data.data.news)
            } catch (e) {
                await router.push('/')
                Vue.$toast.error('Новость не найдена')
            } finally {
                console.log('finally')
            }
        },
    },
    mutations: {
        SET_NEWS(state, data) {
            state.news = data.news
            state.total = data.totalCount
        },
        SET_NEW(state, data) {
            state.new = data
        }
    }
}
