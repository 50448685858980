import http from "@/config/axios/axios";

import qs from 'qs'
export default {
    state: {
        downloads: [],
    },
    getters: {
        getDownloads: state => state.downloads,
    },
    actions: {
        async getDownloads({commit}) {
            try {
                const res = await http.get('/config')
                commit('SET_DOWNLOADS', res.data.data.clients)
            } finally {
                console.log('finally')
            }
        },
    },
    mutations: {
        SET_DOWNLOADS(state, data) {
            state.downloads = data
        },
    }
}
