<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="footer-menu">
            <router-link to="/contacts" class="footer-menu-item">
              {{ $t('contacts') }}
            </router-link>
            <router-link to="/privacy-policy" class="footer-menu-item">
              {{ $t('privacy-policy') }}
            </router-link>
          </div>
        </div>

        <div class="col-md-3">
          <div class="footer-menu">
            <a class="footer-menu-item">
              1801 W MONTANA
            </a>
            <a class="footer-menu-item">
              AVE CHICKASHA
            </a>
            <a class="footer-menu-item">
              OK 73018-6332 USA
            </a>
            <a class="footer-menu-item">
              Support: vanillaprojectrz@gmail.com
            </a>
            <a class="footer-menu-item">
              +13876088683
            </a>
          </div>
        </div>

        <div class="col-md-3">
          <div class="footer-inside">

            <div class="footer-logo">
              <span>
                <img src="@/assets/images/logos/logo.png">
              </span>
              <span>
                Copyright © Vanilla Project™ 2021-2024. All rights reserved.
              </span></div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="footer-menu">
            <router-link to="/license-agreement" class="footer-menu-item">
              {{ $t('license-agreement') }}
            </router-link>
            <router-link to="/rules" class="footer-menu-item">
              {{ $t('rules') }}
            </router-link>
            <br>
            Discord: <b>whynotenot</b>
            Telegram <b>@RappelzOfficial</b>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {}
  },
  components: {},
  async mounted() {
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.footer-menu {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 50px;
}

.footer-menu .footer-menu-item {
  font-size: 16px;
}
</style>
