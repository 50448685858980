import Vue from 'vue'
import Vuex from 'vuex'
import servers from './modules/servers/servers'
import news from './modules/news/news'
import download from './modules/download/download'
import faq from './modules/faq/faq'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('language') || 'ru',
    sidebar: false,
  },
  getters: {
    sidebar: state => state.sidebar
  },
  actions: {
    toggleSideBar(ctx) {
      ctx.commit('toggleSideBar')
    },
    changeLanguage(ctx, language) {
      localStorage.setItem('language', language)
      window.location.reload()
    }
  },
  mutations: {
    toggleSideBar(state) {
      document.body.classList.toggle('sidebar-active');
      state.sidebar = !state.sidebar
    }
  },
  modules: {servers, news, download, faq}
})
