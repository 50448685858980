<template>
  <header id="header">
    <div id="main">

      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logos/logo.png">
        </router-link>
      </div>

      <div class="menu">
        <router-link to="/how-to-connect">
          <i class="fa fa-download mr-2"></i>
          {{ $t('how-to-connect') }}
        </router-link>
        <router-link to="/news">
          {{ $t('news') }}
        </router-link>
        <router-link to="/faq">
          FAQ
        </router-link>
        <a target="_blank" href="https://vanillainfo.wordpress.com/" class="">{{ $t('wiki') }}</a>
      </div>

      <div class="sidebar-collapse">
        <a class="sidebar-trigger is-link" @click="$store.dispatch('toggleSideBar')">
          <i class="fa fa-bars"></i>
        </a>
      </div>

      <div class="user">
        <a class="btn is-link" href="https://panel.vanilla-rappelz.ru/account/login">{{ $t('login') }}</a>
        <a class="btn btn-primary" href="https://panel.vanilla-rappelz.ru/account/register">{{ $t('register') }}</a>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return {}
  },
  components: {},
  async mounted() {
  },
  computed: {},
  methods: {}
}
</script>
