<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>

    <Sidebar/>
    <Language/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Mobile/Sidebar.vue";
import Language from "@/components/Language.vue";
export default {
  name: 'App',
  components: {Language, Sidebar, Footer, Header},
  data: function () {
    return {}
  },
  async mounted() {
  },
  computed: {},
  methods: {}
}
</script>

<style>
@import "@/assets/css/style.css";
</style>

