import http from "@/config/axios/axios";

import qs from 'qs'
export default {
    state: {
        faq: [],
    },
    getters: {
        getFaq: state => state.faq,
    },
    actions: {
        async getFaq({commit}) {
            try {
                const res = await http.get('/faq')
                commit('SET_FAQ', res.data.data.faq)
            } finally {
                console.log('finally')
            }
        },
    },
    mutations: {
        SET_FAQ(state, data) {
            state.faq = data
        },
    }
}
