import Vue from 'vue'
import axios from 'axios'

Vue.config.productionTip = true

const baseURL = Vue.config.productionTip ? 'https://backend.vanilla-rappelz.ru' : ''


const http = axios.create({
    baseURL
})

axios.interceptors.request.use(
    (config) => {
        let language = localStorage.getItem('language')

        if (language) {
            config.headers['Language'] = language;
        }

        return config
    },

    (error) => {

        return Promise.reject(error)
    }
)

export default http
