<template>
  <div class="language" :class="{ active: isActive }">

    <div class="language-item" @click="changeLanguage('ru')">
      <img src="@/assets/images/language/ru.svg"/>
    </div>

    <div class="language-item" @click="changeLanguage('en')">
      <img src="@/assets/images/language/en.svg"/>
    </div>

    <div class="language-item" @click="changeLanguage('ar')">
      <img src="@/assets/images/language/ar.png"/>
    </div>

    <div class="language-item" @click="changeLanguage('tr')">
      <img src="@/assets/images/language/tr.png"/>
    </div>

    <div class="toggle" @click="toggle">
      <i class="fa fa-arrow-right"></i>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Language',
  data: function () {
    return {
      isActive: false,
      language: ''
    }
  },
  async mounted() {
    this.language = localStorage.getItem('language') || 'ru'
  },
  computed: {},
  methods: {
    changeLanguage: function (val) {
      this.$store.dispatch('changeLanguage', val)
    },
    toggle: function () {
      this.isActive = !this.isActive
    },
  }
}
</script>

<style scoped>
.language {
  position: fixed;
  left: 0;
  top: 120px;
  width: fit-content;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(180deg, #191826, #41475f);
  padding: 7px 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.language .language-item {
  cursor: pointer;
}

.language .language-item:not(:first-child) {
  margin-left: 10px;
  display: none;
}

.language.active .language-item {
  display: block;
}

.language .language-item img {
  width: 24px;
  height: 24px;
}

.language .toggle {
  margin-left: 10px;
}

.language .toggle i {
  transition: 0.5s;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.language.active .toggle i {
  transform: rotate(180deg);
}

</style>

