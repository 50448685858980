import http from "@/config/axios/axios";
import qs from 'qs'
export default {
    state: {
        servers: [],
    },
    getters: {
        getServers: state => state.servers,
    },
    actions: {
        async getServers({commit}) {
            try {
                const res = await http.get('/servers')
                commit('SET_SERVERS', res.data.data.servers)
            } finally {
                console.log('finally')
            }
        },
    },
    mutations: {
        SET_SERVERS(state, data) {
            state.servers = data
        },
    }
}
