import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News/News.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy')
  },
  {
    path: '/license-agreement',
    name: 'license-agreement',
    component: () => import('../views/LicenseAgreement')
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('../views/Rules')
  },
  {
    path: '/news/:id',
    name: 'news-item',
    component: () => import('../views/News/Item.vue')
  },
  {
    path: '/how-to-connect',
    name: 'connect',
    component: () => import('../views/HowToConnect.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
