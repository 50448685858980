<template>
  <nav id="sidebar">

    <div class="sidebar-close">
      <a class="sidebar-trigger is-link" @click="$store.dispatch('toggleSideBar')">
        <i class="fa fa-times"></i>
      </a>
    </div>

    <div class="sidebar-logo">
      <a href="/">
        <img src="@/assets/images/logos/logo.png">
      </a>
    </div>

    <div class="sidebar-user">
      <a href="https://panel.vanilla-rappelz.ru/account/login" class="btn is-link">{{ $t('login') }}</a>
      <span>{{ $t('or') }}</span>
      <a href="https://panel.vanilla-rappelz.ru/account/register" class="btn btn-primary is-link">{{ $t('register') }}</a>
    </div>

    <div class="sidebar-menu">
      <ul>
        <li>
          <router-link to="/news">{{ $t('news') }}</router-link>
        </li>
        <li>
          <router-link to="/how-to-connect">{{ $t('how-to-connect') }}</router-link>
        </li>
        <li>
          <router-link to="/faq">FAQ</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Sidebar',
  data: function () {
    return {}
  },
  async mounted() {
  },
  computed: {},
  methods: {}
}
</script>
